import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as FlagUs } from "../images/flags/us.svg";
import { ReactComponent as FlagPl } from "../images/flags/pl.svg";

import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { useWindowScroll } from "react-use";
import clsx from "clsx";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const links = [
  { to: "/", text: "Home" },
  { to: "/#pricing", text: "Pricing plans" },
  { to: "/#channels", text: "Channels" },
  { to: "/", text: "Tutorial" },
  { to: "/", text: "About us" },
  { to: "/#contact-us", text: "Contact us" },
  { to: "/", text: "24h Test" },
];

function NavDrawer() {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);

  return (
    <>
      <button onClick={() => setOpen(true)}>
        <Bars3Icon className="h-8 w-8 xl:hidden" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transform transition ease duration-500"
            enterFrom="translate-x-full opacity-10"
            enterTo="translate-x-0 opacity-100"
            leave="transform transition ease duration-500"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-10"
          >
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-xs">
                    <div className="flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl">
                      <div className="flex items-center justify-between px-6">
                        <div>
                          <Link
                            to="/"
                            className="text-base font-semibold leading-6 text-gray-900"
                            onClick={close}
                            aria-label="Home"
                          >
                            <Logo className="w-24 xl:w-32" />
                          </Link>
                        </div>

                        <button onClick={close}>
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-8 w-8" />
                        </button>
                      </div>

                      <ul className="relative mt-16 flex-1 divide-y text-lg uppercase">
                        {links.map((elem) => (
                          <li key={elem.text}>
                            <Link
                              onClick={close}
                              className="block px-6 py-4 first:rounded-t last:rounded-b hover:bg-i1/10"
                              to={elem.to}
                            >
                              {elem.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Dialog.Panel>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const languages = [
  {
    name: "English",
    flag: (
      <span className="rounded-full ring-1 ring-i1 ring-opacity-50">
        <FlagUs className="h-5 w-5" aria-hidden="true" />
      </span>
    ),
  },
  {
    name: "Polish",
    flag: (
      <span className="rounded-full ring-1 ring-i1 ring-opacity-50">
        <FlagPl className="h-5 w-5" aria-hidden="true" />
      </span>
    ),
  },
];

function LanguageSelector() {
  const [selected, setSelected] = useState(languages[0]);

  return (
    <Listbox
      value={selected}
      onChange={setSelected}
      className="ml-auto mr-4 w-36 xl:ml-12 xl:mr-auto"
    >
      <div className="relative">
        <Listbox.Button className="relative w-full cursor-pointer rounded-md border border-i1 px-10 py-2 text-left hover:bg-i1/10 sm:text-sm">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            {selected.flag}
          </span>
          <span className="mt-[0.2em] block truncate">{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-i1" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-i1 ring-opacity-25 focus:outline-none sm:text-sm">
            {languages.map((language) => (
              <Listbox.Option
                key={language.name}
                className={({ active }) =>
                  clsx(
                    "relative cursor-pointer select-none py-2 pl-10 pr-4",
                    active && "bg-i1 text-white"
                  )
                }
                value={language}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={clsx(
                        "mt-[0.2em] block truncate",
                        selected ? "font-medium" : "font-normal"
                      )}
                    >
                      {language.name}
                    </span>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      {language.flag}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default function NavBar(props) {
  const scroll = useWindowScroll();

  return (
    <nav
      className={clsx(
        "fixed left-0 right-0 top-0 z-50 bg-white",
        scroll.y > 0 && "shadow",
        props.className
      )}
    >
      <div className="container mx-auto flex items-center px-6 py-4">
        <Link to="/" aria-label="Home">
          <Logo className="w-24 xl:w-32" />
        </Link>
        <LanguageSelector />
        <ul className="hidden space-x-8 text-lg uppercase xl:flex">
          {links.map((elem) => (
            <li key={elem.text}>
              <Link className="group relative" to={elem.to}>
                {elem.text}
                <div className="absolute bottom-1.5 left-0 hidden h-0.5 w-0 rounded-full bg-current transition-[width] duration-[400ms] group-hover:w-full lg:block" />
              </Link>
            </li>
          ))}
        </ul>
        <NavDrawer />
      </div>
    </nav>
  );
}
