import { ReactComponent as LogoWhite } from "../images/logo_white.svg";

import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Footer() {
  return (
    <footer className="bg-b4 pb-6 pt-12">
      <div className="container mx-auto px-6 2xl:max-w-screen-xl">
        <div className="mx-auto mb-6 flex max-w-sm flex-wrap gap-8 text-white md:max-w-none md:justify-between lg:flex-nowrap">
          <div>
            <LogoWhite className="mb-6 w-32" />
            <p className="max-w-xs text-justify text-sm font-light">
              We are the official IPTV provider in Poland for over 6 years,
              where we offer the highest quality TV entertainment at affordable
              prices, our goal is to provide enjoyable and reliable TV viewing.
            </p>
          </div>

          <div>
            <h2 className="mb-4 whitespace-nowrap text-2xl">Important Links</h2>
            <ul className="list-inside list-disc text-sm font-light underline">
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/refund">Refund policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy policy</Link>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="mb-4 whitespace-nowrap text-2xl">Navigate To</h2>
            <ul className="list-inside list-disc text-sm font-light underline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/#channels">Channels</Link>
              </li>
              <li>
                <Link to="/#vod">(VOD)</Link>
              </li>
              <li>
                <Link to="/#pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/#contact-us">Contact us</Link>
              </li>
              <li>
                <Link to="/#feedback">Feedbacks</Link>
              </li>
            </ul>
          </div>

          <div className="mx-auto w-full max-w-sm border border-b3 p-6 lg:mx-0 lg:max-w-xs">
            <StaticImage
              src="../images/map_red.png"
              alt=""
              objectFit="contain"
              placeholder="none"
              width={200}
              className="mx-auto !block"
            />
          </div>
        </div>
        <div className="mb-4 h-px bg-white" />
        <div className="text-center text-xs text-white">
          Copyright © 2023 IPTV POLAND | All rights reserved
        </div>
      </div>
    </footer>
  );
}
