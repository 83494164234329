import NavBar from "./NavBar";
import Footer from "./Footer";

import React from "react";
import CookieConsent from "react-cookie-consent";

export default function Layout({ children }) {
  return (
    <div className="flex min-h-screen flex-col pt-20">
      <NavBar />
      <div className="flex-1">{children}</div>
      <div className="fixed bottom-6 left-0 z-40 w-full px-6">
        <CookieConsent
          disableStyles
          contentClasses="w-full"
          buttonWrapperClasses="shrink-0"
          buttonClasses="bg-i1 px-5 pb-2 pt-3 text-white rounded-md hover:bg-i1-dark focus:outline-none"
          containerClasses="border-i1/25 border max-w-screen-lg mx-auto bg-white p-5 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between"
        >
          <span className="mt-[0.2em] inline-block">This website uses cookies to ensure you get the best experience on our
          website.</span>
        </CookieConsent>
      </div>
      <Footer />
    </div>
  );
}
